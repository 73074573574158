import React from "react"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Automated Legal Analysis of Financial Transaction Agreements (GPT, Vector RAG, Parameterized Meta Prompting)"
const pageTitle = "Automated Legal Analysis of Financial Transaction Agreements"
const subTitle = "Amazon Web Services"
const para =
  "By utilizing a RAG framework, the system bypassed the need for fine-tuning the LLM, ensuring scalability and adaptability to complex legal requirements without additional model training."
const blockquote = `"Automated Legal Analysis of Financial Transaction Agreements using GPT, Vector RAG, Parameterized Meta Prompting"`

const cardItems = [
  {
    id: 1,
    heading: `The Need`,
    listItems: (
      <>
        <p>
          {/* <Icon.Square /> */}
          A global investment bank required a solution to migrate 1,800 derivative trades into a new system. 
          Each trade involved a 20-page legal agreement, spanning multiple jurisdictions and subject to complex 
          legal statutes and precedents. With fewer than 600 classified documents available for training, manual 
          processing was infeasible, presenting significant risks in scalability, compliance, and efficiency.
        </p>
        {/* <img  src={MapImg}/> */}
      </>
    ),
  },

  {
    id: 1,
    heading: `The Approach`,
    listItems: (
      <>
        <p>
        Atyeti leveraged a pre-trained large language model (LLM) in conjunction with a vector retrieval-augmented 
        generation (RAG) framework. By integrating legal guidelines, laws, and precedents into the RAG system, 
        the solution ensured accurate extraction and categorization of critical legal details. 
        Parameterized meta prompt engineering enabled precise alignment with jurisdictional requirements, 
        facilitating the automated classification of each agreement.
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: `The Process:`,
    listItems: (
      <ul>
        <li>
        Identifying and organizing relevant legal statutes and guidelines for incorporation into the RAG system. ​
        </li>
        <li>
        Building a robust reference database to ensure comprehensive and jurisdiction-specific analysis. ​
        </li>
        <li>
        Collaborating with legal SMEs to validate the outputs for accuracy and relevance.
        </li>
      </ul>
    ),
  },
  {
    id: 4,
    heading: `Result`,
    listItems: (
      <>
        <p>
        The automated system successfully classified and processed all 1,800 agreements without human intervention. 
        By overcoming the limitations of minimal training data, the solution provided an efficient and compliant method to handling 
        large unstructured data and extracting critical information for onboarding into the new system.
        </p>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query AutomationAWSQuery {
    casedetailsJson(slug: { eq: "automated-legal-analysis" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
